import React, { useState, useEffect } from 'react';
import LogAction from '../Utilities/LogAction';
import TopMenuNavigationLogo from '../TopMenuNavigation/TopMenuNavigationLogo';
import TopMenuNavigationContact from '../TopMenuNavigation/TopMenuNavigationContact';
import { tucsonForm } from '../../../data/formsData';
import Form from '../Form/Form';
import SectionHeader from '../Neutral/SectionHeader';
import ContactPageInfo from '../ContactPage/ContactPageInfo';
import Inster from '../../../assets/Instermain.avif';
import InsterTop from '../../../assets/Modele/Inster/InsterTop.png';
import Carousel from '../Neutral/Carousel';
import PriceList from './PriceList';
import ScrollToTopButton from '../Utilities/ScrollToTopBotton'; // Import the ScrollToTopButton component
import ContactPagePhone from '../ContactPage/ContactPagePhone';

// Function to import all images from a directory
function importAll(r) {
    return r.keys().map(r);
}

// Import all images from the assets/Modele/Inster directory
const images = importAll(require.context('../../../assets/Modele/Inster', false, /\.(png|jpe?g|svg)$/));

const initialFormState = {
    name: '',
    email: '',
    phone: '',
    marketing: false,
    marketing_sms: false,
    marketing_phone: false,
    marketing_email: false,
};

function ModelInsterPage() {
    const [formData, setFormData] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        LogAction('User entered the ModelInsterPage');

        return () => {
            LogAction('User left the ModelInsterPage');
        };
    }, []);

    const validate = () => {
        const newErrors = {};
        const { marketing_phone, marketing_email } = formData;

        if (!marketing_phone && !marketing_email) {
            newErrors.contact = "At least one contact method (marketing phone or marketing email) is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleRestart = () => {
        setIsSubmitted(false);
        setFormData(initialFormState);
    };

    if (isSubmitted) {
        return (
            <div className="form-summary">
                <p>Dziękujemy za przesłanie formularza. Nasz Doradca skontaktuje się z Tobą w przeciągu kilku godzin.</p>
                <button onClick={handleRestart}>Wróć do formularza</button>
            </div>
        );
    }

    return (
        <>
            <header className='topmenunavigation animation-container'>
                <TopMenuNavigationLogo />
                <TopMenuNavigationContact />
            </header>
            <div className='grid-page'>
                <div className='grid-page-1 model-baner model-baner-i5 animation-container'>
                    <img src={Inster} alt="inster" />
                        <h1>Nowy Inster</h1>
                        <h2>Odbierz nawet 40 tysięcy dotacji</h2>
                    </div>
                <SectionHeader title='Umów się na jazdę testową' />
                <div className="grid-page-2-3 article-two-third ">
                    <div className="grid-page-2-3-sub grid-color-white model-configuration animation-container SantaFe-img">
                        <img src={InsterTop} alt="InsterTop"></img>
                    </div>
                    <div className="grid-page-2-3-sub article-content grid-color-white animation-container">
                        <Form 
                            formData={tucsonForm} 
                            formDataValues={formData}
                            setFormData={setFormData}
                            setErrors={setErrors}
                            setIsSubmitted={setIsSubmitted}
                            setIsLoading={setIsLoading}
                            errors={errors}
                            isLoading={isLoading}
                        />
                        <p>Wypełniając powyzszy formularz, wyrazasz zgodę na skontaktowanie się z Tobą. Twoje dane nie zostaną wykorzystane do marketingu przyszłego. <a href='/polityka-prywatnosci'>Kliknij tu po więcej informacji</a></p>
                    </div>
                </div>
                
                <SectionHeader title='Cenniki' />
                
                <div className='grid-page-1 grid-color-white animation-container'>
                    <PriceList modelName='inster' />
                </div>
                <SectionHeader title='Przekonaj się sam' />
                <div className='model-photos animation-container'>
                    <Carousel interval={5000} height='600px' data={images.map(image => ({ image }))} />
                </div>
                
                <SectionHeader title='Odwiedź nasz salon' />
                <div className='grid-page-1 animation-container grid-color-white'>
                    <ContactPageInfo />
                </div>
                <SectionHeader title='Skontaktuj się z nami' />
                <div className='grid-page-2 animation-container grid-color-white'>
                    <div className=''>
                        <Form 
                            formData={tucsonForm} 
                            formDataValues={formData}
                            setFormData={setFormData}
                            setErrors={setErrors}
                            setIsSubmitted={setIsSubmitted}
                            setIsLoading={setIsLoading}
                            errors={errors}
                            isLoading={isLoading}
                        />
                        <p>Wypełniając powyzszy formularz, wyrazasz zgodę na skontaktowanie się z Tobą. Twoje dane nie zostaną wykorzystane do marketingu przyszłego. 
                            <a href='/polityka-prywatnosci'>Kliknij tu po więcej informacji</a>
                        </p>
                        
                    </div>
                    <div>
                        <ContactPagePhone />
                    </div>
                </div>
            </div>
            <ScrollToTopButton /> {/* Add the ScrollToTopButton component */}
        </>
    );
}

export default ModelInsterPage;
