import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import CookieConsentComponent from './components/SinglePages/CookieConsentComponent';
import TopMenuNavigation from './components/TopMenuNavigation/TopMenuNavigation';
import ContactPage from './components/ContactPage/ContactPage';
import CarrierPage from './components/CarrierPage/CarrierPage';
import InsurancePage from './components/InsurancePage/InsurancePage';
import ServicePage from './components/ServicePage/ServicePage';
import BuybackPage from './components/BuybackPage/BuybackPage';
import PageNotFound from './components/PageNotFound/PageNotFound';
import PrivacyPoliticsPage from './components/SinglePages/PrivacyPoliticsPage';
import ModelTucsonPage from './components/ModelsPage/ModelTucsonPage';
import ModelSantaFePage from './components/ModelsPage/ModelSantaFePage';
import Footer from './components/Footer/Footer';  
import ModelIoniq5Page from './components/ModelsPage/ModelIONIQ5Page';
import UsedCarsFinance from './components/ModelsPage/UsedCarsFinance';
import MarketingPage from './components/SinglePages/MarketingPage';
import ModelStariaPage from './components/ModelsPage/ModelStariaPage';
import ModelInsterPage from './components/ModelsPage/ModelInster';

function App() {
  return (
    <Router>
      <AppContent />
      <CookieConsentComponent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();

  return (
    <>
      <div className="grid-mega">
        {/* {location.pathname !== '/tucson' && <TopMenuNavigation />} */}
        <Routes>
          {/* <Route path="/" element={<div>Home</div>} />
          <Route path="/odkupy" element={<BuybackPage />} /> */}
          <Route path="/serwis" element={<ServicePage />} />
          {/* <Route path="/ubezpieczenia" element={<InsurancePage />} />
          <Route path="/kariera" element={<CarrierPage />} />
          <Route path="/kontakt" element={<ContactPage />} /> */}
          <Route path="*" element={<ModelTucsonPage />} />
          <Route path="/polityka-prywatnosci" element={<PrivacyPoliticsPage />} />
          <Route path="/tucson" element={<ModelTucsonPage />} />
          <Route path="/inster" element={<ModelInsterPage />} />
          <Route path="/i30uzywane" element={<UsedCarsFinance />} />
          <Route path="/staria" element={<ModelStariaPage />} />
          <Route path="/marketing" element={<MarketingPage />} />
          <Route path="/santafe" element={<ModelSantaFePage />} />
        </Routes>
      </div>
      {/* {location.pathname !== '/tucson' && <Footer />} */}
    </>
  );
}

export default App;
